import { useFeature, useIsFeatureFlagEnabled } from '@msrceng/component';

/**
 * Feature flag for the Impersonation Feature.
 */
const FEATURE_FLAG_IMPERSONATION_PAGE = 'Impersonation Page';

/**
 * Returns true if the Impersonation Feature is enabled.
 */
export const useIsImpersonationEnabled = () => {
  return useIsFeatureFlagEnabled(FEATURE_FLAG_IMPERSONATION_PAGE);
  // return true;
};

/**
 * Feature controller for the Impersonation Feature.
 */
export const useImpersonationPageFeature = () =>
  useFeature({
    featureName: FEATURE_FLAG_IMPERSONATION_PAGE,
    label: 'Impersonation Report Feature',
    description: 'Adds a new Abuse Report for Impersonations!',
    isInternalOnly: true,
  });
